<template>
  <v-container grid-list-xl>
    <v-layout wrap justify-space-between>
      <v-flex xs12 md12>
        <v-alert :value="alert" type="success">Başarıyla kaldedildi.</v-alert>
      </v-flex>
      <v-flex xs12 md12>
        <v-text-field
          v-model="detail.id"
          label="Product Id"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="detail.state"
          label="State"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="detail.currency"
          label="Currency"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="detail.price"
          label="Price"
          type="number"
          :counter="max"
          required
        ></v-text-field>
        <v-autocomplete
          v-model="detail.type"
          label="Type"
          :counter="max"
          :items="types"
          required
          placeholder="Select..."
        ></v-autocomplete>
        <v-autocomplete
          v-model="detail.priority"
          label="Priority"
          :counter="max"
          :items="priorities"
          required
          placeholder="Select..."
        ></v-autocomplete>
        <v-text-field
          v-model="detail.value"
          type="number"
          label="Value"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="detail.freeSpin"
          label="Free Spin"
          type="number"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="detail.gems"
          label="Gems"
          type="number"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="detail.oldValue"
          label="Old Value"
          type="number"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="detail.image"
          label="Image"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="detail.discountRate"
          label="Discount Rate"
          type="number"
          :counter="max"
          required
        ></v-text-field>
        <v-autocomplete
          v-model="detail.isConsume"
          label="IsConsume"
          :items="isConsume"
          required
          placeholder="Select..."
        ></v-autocomplete>
        <v-text-field
          v-model="detail.order"
          label="Order"
          type="number"
          :counter="max"
          required
        ></v-text-field>
      </v-flex>
      <v-btn color="success" @click="save">Save</v-btn>
    </v-layout>
  </v-container>
</template>
<script>
import router from "@/router";
export default {
  data: () => ({
    detail: {},
    all: [],
    alert: false,
    max: 120,
    types: [
      "Chip",
      "Gems",
      "PromoCode",
      "Multi",
      "SuperVegasWheel",
      "Daruma",
      "Treasure",
      "CoinFlip",
      "WheelOfFortune",
      "Piggy",
      "MultiplierWheel",
      "MultiplierTreasure",
    ],
    priorities: ["Normal", "BestValue", "MostPopular"],
    isConsume: ["true", "false"],
  }),

  methods: {
    async update() {
      await this.$store.dispatch("products/put", this.detail);
      router.push("/products");
    },
    async save() {
      await this.$store.dispatch("products/post", this.detail);
      router.push("/products");
    },
  },
  validateField() {
    this.$refs.form.validate();
  },
  computed: {},
  async created() {
    if (this.$route.params.id) {
      await this.$store.dispatch("products/getById", this.$route.params.id);

      this.detail = this.$store.state.products.detail;
    }
  },
};
</script>
